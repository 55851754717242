@font-face {
    font-family: "Baskerville";
    src: url("./assets/baskerville/BaskervilleBT.ttf");
}

* {
    font-family: Baskerville;
}

body {
    margin: 0px;
    background-image: url('./assets/img/bg.jpg');
}